<template>
  <div>
    <b-row>
      <b-col md="6">
        <h2>{{ $t('Time Limit Settings') }}</h2>
      </b-col>
      <b-col
        md="6"
        class="px-0"
      >
        <b-row class="justiy-content-end align-items-center">
          <b-col
            md="8"
            cols="7"
          >
            <b-form-group>
              <b-form-input
                v-model="searchFor"
                class="float-right"
                :placeholder="$t('Search')"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            cols="5"
            class="px-0"
          >
            <b-button
              v-b-modal.add-user-modal
              class="ml-2 mb-1"
              variant="primary"
            >
              {{ $t('Add Member') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Teams Table -->
    <b-table
      v-if="myTeams.length"
      :fields="fields"
      :items="myTeams"
      :responsive="IS_MOBILE()"
      show-empty
    >
      <!-- User Name -->
      <template #cell(name)="row">
        <div class="col-200">
          <b-avatar
            :src="getAvatar(row.item.avatar)"
            :text="getInitials(row.item.name)"
            :variant="getRandomVariant()"
            badge
            :badge-variant="row.item.isOnline ? 'success' : 'secondary'"
            class="mr-1"
          />
          {{ IS_MOBILE() ? FORMAT_NAME(row.item.name) : row.item.name }}
        </div>
      </template>

      <!-- Limit -->
      <template #cell(hours)="row">
        <div class="col-160">
          <div v-if="row.item.hours">
            {{ row.item.hours }} hrs /
            <span class="text-lowercase">{{ row.item.limitType.substring(0, row.item.limitType.length - 2) }}</span>
          </div>
          <span v-else>{{ $t('No Limit') }}</span>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              variant="primary"
              size="sm"
              class="py-1 mb-1"
              :disabled="!canEdit"
              @click="showModal(row.item)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </b-col>

          <b-col
            cols="6"
            md="3"
          >
            <b-button
              v-if="row.item.hours"
              variant="danger"
              size="sm"
              class="py-1"
              :disabled="!canEdit"
              @click="showModal(row.item, 'delete')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Time Limit Setting Modal -->
    <b-modal
      id="time-limit-setting-modal"
      :title="$t('settings.edit-time-limit', { user: form.name })"
      :ok-title="$t('Submit')"
      :cancel-title="$t('Cancel')"
      :busy="isProcessing"
      @ok="updateTimeLimit"
      @hidden="() => form = {}"
    >
      <b-overlay :show="isProcessing">
        <validation-observer ref="timeLimitSettingForm">
          <!-- Time Limit -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="time limit"
          >
            <b-form-group :label="$t('Time Limit (hrs)')">
              <b-form-input
                v-model="form.hours"
                type="text"
                :placeholder="$t('Time Limit in Hours')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Limit Type -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="limit for"
          >
            <b-form-group :label="$t('Limit For')">
              <b-form-select
                v-model="form.type"
                :options="limitForOptions"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- Time Limit Delete Modal -->
    <b-modal
      id="time-limit-delete-modal"
      :title="$t('settings.remove-time-limit', { user: form.name })"
      :ok-title="$t('Remove')"
      :cancel-title="$t('Cancel')"
      ok-variant="danger"
      @ok="deleteTimeLimit"
    >
      {{ $t('settings.time-limit-remove', { user: form.name }) }}
    </b-modal>

    <!-- Add member -->
    <add-team
      :team-info="currentTeam"
      :members="currentTeam.users.data"
      @updateTeamCount="() => $emit('updated')"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BTable, BButton, BModal, BOverlay, BAvatar,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { checkPermission } from '@/utils/permissions'
import addTeam from '../CRUD/components/addTeam.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BModal,
    BOverlay,
    BAvatar,
    addTeam,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    teams: {
      type: [Object, Array],
      default: () => {},
    },
    currentTeam: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      limitForOptions: [
        {
          value: 'WEEKLY',
          text: this.$t('Weekly'),
        },
        {
          value: 'MONTHLY',
          text: this.$t('Monthly'),
        },
      ],
      searchFor: '',
      selectedProject: this.$store.state.project.selectedProject,
      fields: [
        { label: this.$t('Team Member'), key: 'name', thClass: 'text-capitalize' },
        { label: this.$t('Time Limit (hrs)'), key: 'hours', thClass: 'text-capitalize' },
        {
          label: this.$t('Actions'), key: 'actions', thClass: 'text-capitalize', tdClass: 'px-0 w-25',
        },
      ],
      form: {},
      selectedIndex: null,
      isProcessing: false,
      canEdit: false,
    }
  },
  computed: {
    myTeams() {
      return this.teams ? this.teams.filter(team => team.name.toLowerCase().includes(this.searchFor.toLowerCase())) : []
    },
  },
  mounted() {
    checkPermission('timing.limit', permissions => {
      this.canEdit = permissions['timing.limit'] === true
    })
  },
  methods: {
    updateTimeLimit(bvModal) {
      bvModal.preventDefault()
      this.$refs.timeLimitSettingForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.team.updateTeamMemberLimit({
            userUid: this.form.uid,
            projectUid: this.selectedProject,
            hours: parseInt(this.form.hours, 10) ?? 0,
            type: this.form.type ?? 'WEEKLY',
          }).then(res => {
            this.$emit('updated')
            this.showSuccess(res.data.updateUserProjectTimeLimit.message)
          }).finally(() => {
            this.isProcessing = false
            this.$bvModal.hide('time-limit-setting-modal')
          })
        }
      })
    },
    deleteTimeLimit() {
      this.isProcessing = true
      useApollo.team.updateTeamMemberLimit({
        userUid: this.form.uid,
        projectUid: this.selectedProject,
        hours: 0,
        type: this.form.type ?? 'WEEKLY',
      }).then(res => {
        this.$emit('updated')
        this.showSuccess(res.data.updateUserProjectTimeLimit.message)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showModal(user, action) {
      this.form = {
        uid: user.uid,
        name: user.name,
        hours: user.hours,
        type: user.limitType ?? 'WEEKLY',
      }
      this.$nextTick(() => {
        if (action === 'delete') this.$bvModal.show('time-limit-delete-modal')
        else this.$bvModal.show('time-limit-setting-modal')
      })
    },
  },
}
</script>

<style scoped>
.col-200 {
  min-width: 200px;
}
.col-160 {
  min-width: 160px;
}
</style>
