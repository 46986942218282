<template>
  <b-tabs
    nav-class="nav-left"
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    nav-wrapper-class="col-md-3 col-12"
    pills
    vertical
  >
    <b-tab>
      <template #title>
        <span class="font-weight-bold">
          <feather-icon icon="ClockIcon" />
          {{ $t('Time Limit Settings') }}
        </span>
      </template>

      <b-card>
        <time-limit-setting
          :teams="teams"
          :current-team="$route.params.team"
          @updated="() => fetchTeamDetails()"
        />
      </b-card>
    </b-tab>
  </b-tabs>
</template>
<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import TimeLimitSetting from './TimeLimitSetting.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    TimeLimitSetting,
  },
  data() {
    return {
      teams: [],
      selectedProject: this.$store.state.project.selectedProject,
    }
  },

  computed: {
    uuid() {
      return this.$route.params.team.uuid
    },
  },
  mounted() {
    this.fetchTeamDetails()
  },

  methods: {
    fetchTeamDetails() {
      useApollo.team.getTeamDetails({ projectUid: this.selectedProject, teamUid: this.uuid }).then(res => {
        const teamMembers = res.data.project?.teams?.data[0]?.users.data
        this.teams = teamMembers.map(member => ({
          name: member.name,
          uid: member.uuid,
          avatar: member.avatar,
          isOnline: member.isOnline,
          hours: member?.projectSettings?.data[0]?.information?.hours,
          limitType: member?.projectSettings?.data[0]?.information?.type,
        }))
      })
    },
  },
}
</script>
